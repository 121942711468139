import { useContext, useMemo, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import { UserContext } from "../../context/UserContext";

import { Space, Switch, Table } from "antd";
import {
  LineChartOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import ClientsService from "../../services/Clients";

import AdaptorUsageIcon from "../../components/AdaptorUsageIcon";

const ClientAdaptors = () => {
  const { token } = useContext(UserContext);

  const queryClient = useQueryClient();
  const { client } = useOutletContext<any>();

  const [adaptors, setAdaptors] = useState<Array<Record<any, any>>>(
    client.adaptors
  );

  const { mutate } = useMutation({
    mutationFn: ({ name, visible }: Record<any, any>) =>
      ClientsService.setAdaptorVisibility(token!)(client, name, visible),
    onError: () => {
      console.log(`Could not update visibility`);
    },
    onSuccess: (data, { name, visible }, context) => {
      setAdaptors((prev) =>
        prev.map((ad: Record<any, any>) =>
          ad.name === name ? { ...ad, visible } : ad
        )
      );

      queryClient.invalidateQueries({
        queryKey: ["ClientsService.getClient", client?.id],
      });
    },
  });

  const columns = useMemo<any>(
    () => [
      {
        title: "Usage",
        dataIndex: "usage",
        width: 30,
        render: (_: any, { installed, usage }: Record<any, any>) => (
          <AdaptorUsageIcon usage={usage} installed={installed} />
        ),
      },
      {
        title: "Adaptor",
        dataIndex: "name",
      },
      {
        title: "Adaptor visibility",
        dataIndex: "visible",
        key: "visible",
        width: 150,
        render: (_: any, { visible, installed, name }: Record<any, any>) => (
          <Space>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              disabled={installed}
              checked={visible}
              onChange={() =>
                installed ? null : mutate({ name, visible: !visible })
              }
            />
          </Space>
        ),
      },
      {
        title: "Actions",
        dataIndex: "_actions",
        key: "_actions",
        width: 120,
        render: (_: any, { name }: { name: string }) => (
          <Space align="end">
            <Link to={`/clients/${client.id}/adaptors/${name}`}>
              <LineChartOutlined />
            </Link>
          </Space>
        ),
      },
    ],
    [client, mutate]
  );

  return (
    <>
      <Table dataSource={adaptors || []} columns={columns} rowKey="name" />
    </>
  );
};

export default ClientAdaptors;
