import { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { Breadcrumb, Card, Col, Row, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";

import { UserContext } from "../../context/UserContext";

import GroupedMonthlyBarChart from "../../components/charts/GroupedMonthlyBarChart";
import MonthlyBarChart from "../../components/charts/MonthlyBarChart";

import ClientsService from "../../services/Clients";
import AdaptorsService from "../../services/Adaptors";
import ChartsService from "../../services/Charts";

import { calculatePercentages } from "../../Utils";

const ClientAdaptor = () => {
  const { token } = useContext(UserContext);
  const { clientId, adaptorId } = useParams();
  const navigate = useNavigate();

  const { data: client } = useQuery({
    queryKey: ["ClientsService.getClient", clientId],
    queryFn: () => ClientsService.getClient(token!, clientId!),
    enabled: !!clientId,
  });

  const { data: adaptor } = useQuery({
    queryKey: ["daptorsService.getAdaptor", adaptorId],
    queryFn: () => AdaptorsService.getAdaptor(token!, adaptorId!),
    enabled: !!adaptorId,
  });

  const { data: activitiesByAdaptors } = useQuery({
    queryKey: [
      "ChartsService.getAllActivitiesForClientAndAdaptor",
      clientId,
      adaptorId,
    ],
    queryFn: () =>
      ChartsService.getAllActivitiesForClientAndAdaptor(
        token!,
        clientId!,
        adaptorId!
      ),
    enabled: !!clientId && !!adaptorId,
  });

  const { data: errorsByAdaptors } = useQuery({
    queryKey: [
      "ChartsService.getFailedActivitiesForClientAndAdaptor",
      clientId,
      adaptorId,
    ],
    queryFn: () =>
      ChartsService.getFailedActivitiesForClientAndAdaptor(
        token!,
        clientId!,
        adaptorId!
      ),
    enabled: !!clientId && !!adaptorId,
  });

  const errorsByAdaptorsWithPercentages = useMemo(
    () =>
      calculatePercentages(
        activitiesByAdaptors,
        errorsByAdaptors,
        "count",
        "percentage",
        (v1, v2) => v1.month === v2.month
      ),
    [activitiesByAdaptors, errorsByAdaptors]
  );

  const { data: totalActionsByAdaptors } = useQuery({
    queryKey: [
      "ChartsService.getAllActionsForClientAndAdaptor",
      clientId,
      adaptorId,
    ],
    queryFn: () =>
      ChartsService.getAllActionsForClientAndAdaptor(
        token!,
        clientId!,
        adaptorId!
      ),
    enabled: !!clientId && !!adaptorId,
  });

  const { data: failedActionsByAdaptors } = useQuery({
    queryKey: [
      "ChartsService.getFailedActionsForClientAndAdaptor",
      clientId,
      adaptorId,
    ],
    queryFn: () =>
      ChartsService.getFailedActionsForClientAndAdaptor(
        token!,
        clientId!,
        adaptorId!
      ),
    enabled: !!clientId && !!adaptorId,
  });

  const activitiesCount = useMemo(() => {
    if (!Array.isArray(totalActionsByAdaptors)) {
      return 0;
    }

    return totalActionsByAdaptors
      .map((v) => v.action)
      .filter((v, idx, arr) => arr.indexOf(v) === idx).length;
  }, [totalActionsByAdaptors]);

  const failedActionsByAdaptorsWithPercentages = useMemo(
    () =>
      calculatePercentages(
        totalActionsByAdaptors,
        failedActionsByAdaptors,
        "count",
        "percentage",
        (v1, v2) => v1.month === v2.month && v1.action === v2.action
      ),
    [totalActionsByAdaptors, failedActionsByAdaptors]
  );

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: "1rem" }}
        items={[
          {
            title: <HomeOutlined />,
            onClick: () => navigate("/"),
          },
          {
            title: "Clients",
            onClick: () => navigate("/clients"),
          },
          {
            title: client?.name,
          },
          {
            title: "Adaptors",
            onClick: () => navigate(`/clients/${client.id}/adaptors`),
          },
          {
            title: adaptor?.name,
          },
        ]}
      />

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card loading={activitiesByAdaptors === undefined}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Total Business Activities by Adapter
            </Typography.Title>

            <MonthlyBarChart
              data={activitiesByAdaptors}
              entityType="Activities"
              label="month"
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card loading={errorsByAdaptorsWithPercentages === undefined}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Failed Business Activities by Adapter
            </Typography.Title>

            <MonthlyBarChart
              data={errorsByAdaptorsWithPercentages}
              entityType="Activities"
              label="month"
              percentage="percentage"
            />
          </Card>
        </Col>

        {activitiesCount > 1 ? (
          <>
            <Col span={12}>
              <Card loading={totalActionsByAdaptors === undefined}>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  Total Business Activities by Adapter
                </Typography.Title>

                <GroupedMonthlyBarChart data={totalActionsByAdaptors} />
              </Card>
            </Col>
            <Col span={12}>
              <Card
                loading={failedActionsByAdaptorsWithPercentages === undefined}
              >
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                  Failed Business Activities by Adapter
                </Typography.Title>

                <GroupedMonthlyBarChart
                  data={failedActionsByAdaptorsWithPercentages}
                  percentage="percentage"
                />
              </Card>
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
};

export default ClientAdaptor;
