import { useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";

import { Skeleton, Space, Table } from "antd";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";

const ClientParameters = () => {
  const { client } = useOutletContext<any>();

  const [visibleField, setVisibleField] = useState<Array<string>>([]);

  const columns = useMemo(
    () => [
      {
        title: "Parameter",
        dataIndex: "name",
        key: "name",
        width: 200,
      },
      {
        title: "Value",
        dataIndex: "value",
        key: "value",
        render: (value: string, { name }: { name: string }) => (
          <Space>
            {visibleField.includes(name) ? (
              value
            ) : (
              <Skeleton paragraph={false} title={{ width: 300 }} />
            )}

            {visibleField.includes(name) ? (
              <EditOutlined onClick={() => console.log("test")} />
            ) : (
              <EyeOutlined
                onClick={() => setVisibleField((prev) => [...prev, name])}
              />
            )}
          </Space>
        ),
      },
    ],
    [visibleField]
  );

  const parameters: Array<{ name: string; value: string }> = useMemo(() => {
    return Object.entries(client?.parameters || {}).reduce(
      (acc: Array<{ name: string; value: string }>, val: Record<any, any>) => {
        return [...acc, { name: val[0], value: val[1] }];
      },
      []
    );
  }, [client]);

  return (
    <>
      <Table dataSource={parameters} columns={columns} rowKey="name" />;
    </>
  );
};

export default ClientParameters;
