interface AdaptorProps {
  name: string;
  icon?: string;
}

const Adaptor: Record<string, AdaptorProps> = {
  MOT: {
    name: "MOT",
    icon: "/adaptors/MOT_label.svg",
  },
  AUTOTRADER: {
    name: "Autotrader",
    icon: "/adaptors/AutoTrader_Logo.jpeg",
  },
  KEYLOOP: {
    name: "Keyloop",
    icon: "/adaptors/Keyloop_Icon.png",
  },
  KEYLOOP_DRIVE_VEHICLE: {
    name: "Keyloop Drive",
    icon: "/adaptors/Keyloop_Icon.png",
  },
  KEYLOOP_AUTOCLOUD_VEHICLE: {
    name: "Keyloop Autocloud",
    icon: "/adaptors/Keyloop_Icon.png",
  },
  MD_Voicebox: {
    name: "Marketing Delivery",
    icon: "/adaptors/Stockbox_Icon.jpeg",
  },
  RTC: {
    name: "RTC",
    icon: "/adaptors/RTC_Icon.svg",
  },
  CAP: {
    name: "CAP",
    icon: "/adaptors/Cap_Hpi_Icon.png",
  },
  PARTNER: {
    name: "Partner",
  },
  LOMBARD: {
    name: "Lombard",
    icon: "/adaptors/Lombard_Icon.png",
  },
  PROCESSING: {
    name: "Processing",
  },
  CARGURUS: {
    name: "CarGurus",
    icon: "/adaptors/CARGURUS_Icon.jpeg",
  },
  EMAX: {
    name: "EnquiryMax",
  },
  WEBHOOKS: {
    name: "Webhooks",
  },
  CFA_POLK: {
    name: "POLK CFA",
    icon: "/adaptors/CFA_Icon.svg",
  },
  AUTOTRADER_VALUATION: {
    name: "Autotrader valuation",
    icon: "/adaptors/AutoTrader_Valuation_Stacked_Logo.svg",
  },
  AUTOTRADER_CONFIGURATOR: {
    name: "Autotrader Configurator",
    icon: "/adaptors/AutoTrader_Logo.jpeg",
  },
  FERRARI: {
    name: "Ferrari",
    icon: "/adaptors/Ferrari_Logo.png",
  },
  CAPHPI: {
    name: "CAP HPI",
    icon: "/adaptors/Cap_Hpi_Icon.png",
  },
  CALLDRIP: {
    name: "CallDrip",
  },
  AUTOPROCESS: {
    name: "Autoprocess",
    icon: "/adaptors/AutoProcess_Logo.png",
  },
  VEHICLE_LOOKUP: {
    name: "Vehicle Lookup",
  },
  PROVENANCE_CHECK: {
    name: "Provenance Check",
  },
  AUTOMOTIVE_COMPLIANCE: {
    name: "Automotive Compliance",
    icon: "/adaptors/Automotive_Compliance_Icon.webp",
  },
  ATG: {
    name: "ATG",
  },
  CITNOW_SALES: {
    name: "CitNow Sales",
  },
  CITNOW_WORKSHOP: {
    name: "CitNow Workshop",
  },
};

export default Adaptor;
