import { useMemo } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./components/layout/Root";

import Adaptor from "./components/layout/Adaptor";
import Client from "./components/layout/Client";

import Home from "./pages/Home";
import Error from "./pages/Error";

import Adaptors from "./pages/adaptor/Adaptors";
import AdaptorActivity from "./pages/adaptor/AdaptorActivity";
import AdaptorClients from "./pages/adaptor/AdaptorClients";
import AdaptorParameters from "./pages/adaptor/AdaptorParameters";

import Clients from "./pages/clients/Clients";
import ClientActivity from "./pages/clients/ClientActivity";
import ClientAdaptors from "./pages/clients/ClientAdaptors";
import ClientParameters from "./pages/clients/ClientParameters";

import ClientAdaptor from "./pages/clients/ClientAdaptor";

const App = () => {
  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: "/",
          element: <Root />,
          errorElement: (
            <Root>
              <Error />
            </Root>
          ),
          children: [
            {
              path: "/adaptors",
              element: <Adaptors />,
            },
            {
              path: "/adaptors/:adaptorId",
              element: <Adaptor />,
              children: [
                {
                  path: "/adaptors/:adaptorId/activity",
                  element: <AdaptorActivity />,
                },
                {
                  path: "/adaptors/:adaptorId/clients",
                  element: <AdaptorClients />,
                },
                {
                  path: "/adaptors/:adaptorId/parameters",
                  element: <AdaptorParameters />,
                },
              ],
            },
            {
              path: "/clients",
              element: <Clients />,
            },
            {
              path: "/clients/:clientId",
              element: <Client />,
              children: [
                {
                  path: "/clients/:clientId/activity",
                  element: <ClientActivity />,
                },
                {
                  path: "/clients/:clientId/adaptors",
                  element: <ClientAdaptors />,
                },
                {
                  path: "/clients/:clientId/parameters",
                  element: <ClientParameters />,
                },
              ],
            },
            {
              path: "/clients/:clientId/adaptors/:adaptorId",
              element: <ClientAdaptor />,
            },
            {
              path: "/",
              element: <Home />,
            },
          ],
        },
      ]),
    []
  );

  return <RouterProvider router={router} />;
};

export default App;
