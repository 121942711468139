import { useCallback, useContext, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Button, Collapse, Descriptions, Form, Input, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { UserContext } from "../../context/UserContext";

import ClientsService from "../../services/Clients";

const CreateCustomerButton = () => {
  const { token } = useContext(UserContext);
  const queryClient = useQueryClient();

  const createCustomer = useMutation({
    mutationFn: (client) => ClientsService.createClient(token!, client),
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries({
        queryKey: ["ClientsService.getClients"],
      });
      Modal.success({
        title: `Customer "${data.name}" created`,
        width: 800,
        content: (
          <Descriptions
            title="GetAUTO.io keys"
            column={1}
            bordered
            labelStyle={{ width: "200px" }}
          >
            <Descriptions.Item label="Client Id">
              {data.clientId}
            </Descriptions.Item>
            <Descriptions.Item label="Client Secret">
              {data.clientSecret}
            </Descriptions.Item>
          </Descriptions>
        ),
      });
    },
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = useCallback(() => {
    form.resetFields();
    form.setFieldsValue({
      name: "",
      username: "",
      password: "",
      clientId: "",
      clientSecret: "",
      baseUrl: "",
      authUrl: "https://login.salesforce.com/services/oauth2/token",
      grantType: "password",
      namespace: "ga",
    });
    setModalVisible(true);
  }, [form]);

  const handleCancel = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleSubmit = useCallback(
    (value: any) => {
      console.log(value);
      createCustomer.mutate(value);
    },
    [createCustomer]
  );

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
        Add New Customer
      </Button>

      <Modal
        title="Create customer"
        open={modalVisible}
        onOk={form.submit}
        onCancel={handleCancel}
        width={800}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="name"
            label="Customer name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Collapse
            collapsible="icon"
            ghost
            defaultActiveKey={["salesforce"]}
            items={[
              {
                key: "salesforce",
                label: <b>Salesforce Settings</b>,
                children: (
                  <>
                    <Form.Item
                      name="baseUrl"
                      label="URL"
                      rules={[{ required: true, type: "url" }]}
                    >
                      <Input type="url" />
                    </Form.Item>
                    <Form.Item
                      name="authUrl"
                      label="Auth URL"
                      rules={[{ required: true, type: "url" }]}
                    >
                      <Input type="url" />
                    </Form.Item>
                    <Form.Item
                      name="username"
                      label="Username"
                      rules={[{ required: true, type: "email" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[{ required: true }]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="clientId"
                      label="Client Id"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="clientSecret"
                      label="Client Secret"
                      rules={[{ required: true }]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      name="grantType"
                      label="Grant type"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="namespace"
                      label="Namespace"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </>
                ),
              },
            ]}
          />
        </Form>
      </Modal>
    </>
  );
};

export default CreateCustomerButton;
