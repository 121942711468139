/* eslint-disable no-template-curly-in-string */

import React from "react";
import ReactDOM from "react-dom/client";

import { ConfigProvider } from "antd";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { Amplify } from "aws-amplify";
import { Authenticator, View, Image, useTheme } from "@aws-amplify/ui-react";

import UserContextProvider from "./context/UserContext";

import Config from "./Config";

import App from "./App";

import "./index.css";
import "@aws-amplify/ui-react/styles.css";

const formValidationMessages = {
  required: "'${label}' is required!",
  types: {
    url: "'${label}' is not a valid URL",
    email: "'${label}' is not a valid Email address",
  },
};

const queryClient = new QueryClient();

const [userPoolId, userPoolClientId] = atob(Config.clientId).split(":");

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: "",
      userPoolId,
      userPoolClientId,
      allowGuestAccess: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="GETAUTO.io Dashboard" src="/logo-light.png" />
      </View>
    );
  },
};

root.render(
  <React.StrictMode>
    <Authenticator hideSignUp={true} components={components}>
      {({ signOut, user }) => (
        <UserContextProvider user={user} signOut={signOut}>
          <QueryClientProvider client={queryClient}>
            <ConfigProvider form={{ validateMessages: formValidationMessages }}>
              <App />
            </ConfigProvider>
          </QueryClientProvider>
        </UserContextProvider>
      )}
    </Authenticator>
  </React.StrictMode>
);
