import { get, post, put, remove } from "./http";

const ClientsService = {
  getClientsAdaptorsMap: (token: string) => get(token)("/admin/clients/map"),
  getClients: (token: string) => get(token)(`/admin/clients`),
  getClient: (token: string, clientId: string) =>
    get(token)(`/admin/clients/${clientId}`),
  createClient: (token: string, client: any) =>
    post(token)(`/admin/clients`, client),

  setAdaptorVisibility:
    (token: string) => (client: any, adaptorName: string, visible: boolean) =>
      (visible ? put : remove)(token)(
        `/admin/clients/${client.id}/adaptors/${adaptorName}/visible`,
        {}
      ),
};
export default ClientsService;
