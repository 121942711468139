import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import {
  Breadcrumb,
  Card,
  Col,
  Pagination,
  Row,
  Skeleton,
  Space,
  Image,
  Flex,
} from "antd";
import {
  SettingOutlined,
  LineChartOutlined,
  TeamOutlined,
  WarningOutlined,
  HomeOutlined,
} from "@ant-design/icons";

import { UserContext } from "../../context/UserContext";

import AdaptorsService from "../../services/Adaptors";

import Adaptor from "../../Adaptors";

const Adaptors = () => {
  const { token } = useContext(UserContext);

  const { data: adaptors } = useQuery({
    queryKey: ["AdaptorsService.getAdaptors"],
    queryFn: () => AdaptorsService.getAdaptors(token!),
  });

  const [page, setPage] = useState<number>(1);

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: "2rem" }}
        items={[
          {
            href: "/",
            title: <HomeOutlined />,
          },
          {
            title: "Adaptors",
          },
        ]}
      />
      <Skeleton loading={adaptors === undefined}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row gutter={[16, 16]}>
            {adaptors
              ?.slice((page - 1) * 12, page * 12)
              .map((row: Record<any, any>) => (
                <Col span={6} key={row.name}>
                  <Card
                    title={Adaptor[row.name]?.name || row.name}
                    extra={
                      row.initialized ? null : (
                        <WarningOutlined style={{ color: "red" }} />
                      )
                    }
                    actions={[
                      <Link to={`/adaptors/${row.name}/activity`}>
                        <LineChartOutlined key="charts" />
                      </Link>,
                      <Link to={`/adaptors/${row.name}/clients`}>
                        <TeamOutlined key="clients" />
                      </Link>,
                      <Link to={`/adaptors/${row.name}/parameters`}>
                        <SettingOutlined key="setting" />
                      </Link>,
                    ]}
                  >
                    <Flex justify="center" style={{ height: "48px" }}>
                      <Image
                        height={48}
                        src={
                          Adaptor[row.name]?.icon ||
                          "/adaptors/Default_Adaptor_Icon.png"
                        }
                        preview={false}
                      />
                    </Flex>
                  </Card>
                </Col>
              ))}
          </Row>

          <Pagination
            defaultCurrent={1}
            total={adaptors?.length || 0}
            defaultPageSize={12}
            onChange={(p) => setPage(p)}
          />
        </Space>
      </Skeleton>
    </>
  );
};

export default Adaptors;
